import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { css, useTheme } from "@emotion/react"
import { mediaQuery } from "../utils/mediaQuery"

const NotFoundPage = () => {
  const theme = useTheme()
  return (
    <Layout>
      <Seo title="404: Not found" />
      <div
        css={css`
          color: black;
          background-color: white;
          min-height: 75vh;
          text-align: center;

          ${mediaQuery[1]} {
            margin-top: ${theme.heights.header};
          }
        `}
      >
        <h1>PAGE NOT FOUND</h1>
        <p>Tu jo not know where you're going?</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
